import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco-root.module';
import { CookiesRootModule } from './cookies-root.module';

import { AppComponent } from './app.component';
import { ContactFormModule } from './containers/contact-form.module';
import { FooterComponent } from './components/footer/footer.component';
import { IntroductionComponent } from './containers/introduction/introduction.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { NavigationComponent } from './components/navigation/navigation.component';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

registerLocaleData(localeDe);

@NgModule({
  declarations: [AppComponent, FooterComponent, IntroductionComponent, NavigationComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    CookiesRootModule,
    TranslocoRootModule,
    BrowserAnimationsModule,
    ContactFormModule,

    NgxMaskModule.forRoot(options),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
