<div class="navigation" *transloco="let t">
  <button routerLink="/calculator/start" [ngClass]="{active: activeTab === 'start'}">
    <app-button theme="primary"> {{ t('navigation.start') }} </app-button>
  </button>
  <button routerLink="/calculator/summary" [ngClass]="{active: activeTab === 'summary'}">
    <app-button theme="primary"> {{ t('navigation.summary') }} </app-button>
  </button>
  <button routerLink="/calculator/summary/emission" [ngClass]="{active: activeTab === 'emission'}">
    <app-button theme="primary"> {{ t('navigation.emission') }} </app-button>
  </button>
</div>
