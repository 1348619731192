<h1 class="logo">
  <ng-container *transloco="let t">
    <img
      class="logo__icon"
      src="/assets/images/icon.svg"
      alt="{{ t('title') }}"
    />
    <span class="logo__text">{{ t('title') }}</span>
  </ng-container>
</h1>
<app-navigation *ngIf="router.url !== '/'" [activeTab]="activeTab"></app-navigation>
<router-outlet></router-outlet>

<ng-container *transloco="let t">
  <app-card class="expandable-panel">
    <header>
      <h3 (click)="onToggleExpansionPanel($event)">
        {{ t('contactForm.title') }}
        <span class="material-icons"> keyboard_arrow_down </span>
      </h3>
    </header>
    <section>
      <app-no-encapsulation>
        <app-contact-form></app-contact-form>
      </app-no-encapsulation>
    </section>
  </app-card>

  <app-card class="expandable-panel">
    <header>
      <h3 (click)="onToggleExpansionPanel($event)">
        {{ t('sources.title') }}
        <span class="material-icons"> keyboard_arrow_down </span>
      </h3>
    </header>
    <section>
      <app-no-encapsulation>
        <div
          class="sources"
          [innerHTML]="'sources.description' | transloco | safe: 'html'"
        ></div>
      </app-no-encapsulation>
    </section>
  </app-card>

  <app-card class="expandable-panel">
    <header>
      <h3 (click)="onToggleExpansionPanel($event)">
        {{ t('disclaimer.title') }}
        <span class="material-icons"> keyboard_arrow_down </span>
      </h3>
    </header>
    <section>
      <app-no-encapsulation>
        <div
          class="disclaimer"
          [innerHTML]="'disclaimer.description' | transloco | safe: 'html'"
        ></div>
      </app-no-encapsulation>
    </section>
  </app-card>

  <app-card class="expandable-panel">
    <header>
      <h3 (click)="onToggleExpansionPanel($event)">
        {{ t('releaseNotes.title') }}
        <span class="material-icons"> keyboard_arrow_down </span>
      </h3>
    </header>
    <section>
      <app-no-encapsulation>
        <div
          class="disclaimer"
          [innerHTML]="'releaseNotes.description' | transloco | safe: 'html'"
        ></div>
      </app-no-encapsulation>
    </section>
  </app-card>

  <footer class="expandable-panel">
    <app-footer></app-footer>
  </footer>
</ng-container>
